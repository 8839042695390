//
//
//
//
//
//
//

export default {
  name: 'Delivery',
  props: {
    type: {
      type: String,
      default: 'primary',
    },
    text: {
      type: String,
      default: '',
      required: true,
    },
  },
}
