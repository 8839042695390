//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { IS_TABLET, IS_MOBILE } from '~/store/device/type.device'
import expansionsFiles from '~/mixins/expansionsFiles'
export default {
  name: 'Gallery',
  components: {
    Labels: () => import('../Labels.vue'),
    MediaFiles: () => import('~/components/UI/MediaFiles.vue'),
    Slider: () => import('~/components/UI/Slider.vue'),
  },
  mixins: [expansionsFiles],
  props: {
    items: {
      type: Array,
      default: () => [],
      require: true,
    },
    labels: {
      type: Array,
      default: () => [],
      require: true,
    },
  },

  data() {
    return {
      selectItem: this.items[0],
      scrollCount: 0,
      videoSettings: {
        autoplay: true,
        controls: true,
        muted: true,
        loop: true,
      },
    }
  },

  computed: {
    isTablet() {
      return this.$store.getters[`device/${IS_TABLET}`]
    },
    isMobile() {
      return this.$store.getters[`device/${IS_MOBILE}`]
    },
    itemStyles() {
      return {
        transform: `translateY(calc(${-100 * this.scrollCount}% - ${
          this.scrollCount * 20
        }px) )`,
      }
    },
  },
  watch: {
    items(val) {
      this.selectItem = val[0]
    },
  },

  methods: {
    onSelectItem(item) {
      this.selectItem = item
    },
    onScrollGallery(type) {
      switch (type) {
      case 'prev':
        this.scrollCount--
        break

      case 'next':
        this.scrollCount++
        break

      default:
        break
      }
    },
  },
}
