//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'Tabs',
  props: {
    value: {
      type: Array,
      default: () => [],
      required: true,
    },
  },
  data() {
    return {
      valueInternal: this.value,
    }
  },
  watch: {
    value(val) {
      this.valueInternal = val
    },
  },
  mounted() {
    const activeTab = this.$refs.tabs.find((tab) =>
      tab.classList.contains('tab--active'),
    )
    this.setScroll(activeTab)
  },
  methods: {
    changeTab(e, { id }) {
      this.setScroll(e.target)
      this.valueInternal.forEach((t) => {
        t.isActive = t.id === id
      })
      this.$emit('input', this.valueInternal)
    },
    getPreviousSiblings(el) {
      const siblings = []
      // eslint-disable-next-line
      while ((el = el.previousElementSibling)) {
        siblings.push(el)
      }
      return siblings
    },
    setScroll(tab) {
      const prevElements = this.getPreviousSiblings(tab)
      // eslint-disable-next-line
      const sum = prevElements.reduce((acc, el) => (acc += el.offsetWidth), 0)
      let left = sum - (this.$refs.wrapper.offsetWidth - tab.offsetWidth) / 2
      if (left < 0) left = 0
      this.$refs.wrapper.scrollTo({ left, behavior: 'smooth' })
    },
  },
}
