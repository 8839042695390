//
//
//
//
//
//

export default {
  name: 'Logo',
  props: {
    title: {
      type: String,
      default: 'Furniture',
    },
    color: {
      type: String,
      default: '#0f0f0f',
    },
  },
  computed: {
    styles() {
      return {
        color: this.color,
      }
    },
  },
}
