//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'Rating',
  props: {
    styleStar: {
      type: Object,
      default: () => ({
        padding: 8,
        size: 20,
        cursor: 'default',
      }),
    },
    value: {
      type: Number,
      default: 0,
    },
    average: {
      type: Number,
      default: null,
    },
    text: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    description: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Object,
      default: () => {},
    },
    error: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    slug: {
      type: String,
      default: ''
    },
    entity: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      currentIndex: this.$props.average ? this.$props.average : this.average,
      hoverSelectedCurrentStar: null,
      selectedCurrentStar: null,
      clickStars: false,
    }
  },
  computed: {
    starVars() {
      return {
        '--padding': this.styleStar.padding + 'px',
        '--cursor': this.styleStar.cursor,
      }
    },
  },
  watch: {
    average(val) {
      this.currentIndex = val
      this.$emit('clearError')
    },
  },
  methods: {
    stroke(index) {
      if(this.$props.average) {
        return index <= this.currentIndex ? '#F7B100' : this.$color.grey
      } else {
        return index <= this.hoverSelectedCurrentStar ? '#F7B100' : this.$color.grey
      }
    },
    fill(index) {
      if(this.$props.average) {
        return index <= this.currentIndex ? '#F7B100' : 'none'
      } else {
        return index <= this.hoverSelectedCurrentStar ? '#F7B100' : 'none'
      }
    },
    selectCurrentStar(n) {
      if(!this.$props.value) {
        this.hoverSelectedCurrentStar = n
      }
    },
    clearCurrentStar() {
      if(this.hoverSelectedCurrentStar && !this.clickStars) {
        this.hoverSelectedCurrentStar = null
      }
      if(!this.clickStars) {
        this.clickStars = false
        this.selectedCurrentStar = null
      }
      if(this.clickStars && this.selectedCurrentStar) {
        this.hoverSelectedCurrentStar = this.selectedCurrentStar
      }
    },
    clickStar(n) {
      if(!this.$props.value) {
        if(!this.clickStars) {
          this.selectedCurrentStar = n
          this.clickStars = true
          this.$emit('select', this.selectedCurrentStar)
        } else if(this.clickStars && this.selectedCurrentStar === n) {
          this.clickStars = false
          this.$emit('select', false)
        } else {
          this.selectedCurrentStar = n
          this.$emit('select', this.selectedCurrentStar)
        }
      }
    },
  },
}
