//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'DarkArrows',
  methods: {
    handleNext() {
      this.$emit('click:next')
    },
    handlePrev() {
      this.$emit('click:prev')
    },
  },
}
