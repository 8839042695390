//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import MediaFiles from './MediaFiles.vue'

export default {
  name: 'PictureRowList',
  components: { MediaFiles },
  model: {
    event: 'click',
    prop: 'value',
  },
  props: {
    value: {
      type: [Object, String, Number, Array],
      default: () => ({}),
      require: true,
    },
    items: {
      type: Array,
      default: () => [],
      require: true,
    },
    sliceCount: {
      type: Number,
      default: 4,
    },
    name: {
      type: String,
      default: 'picture',
    },
    to: {
      type: [Object, String],
      default: () => ({}),
    },
    hasActive: {
      type: Boolean,
      default: true,
    },
    itemW: {
      type: String,
      default: '40px',
    },
    itemH: {
      type: String,
      default: '40px',
    },
    offset: {
      type: String,
      default: '10px',
    },
    customAction: {
      type: Boolean,
      default: false,
    },
    entity: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      selected: 0,
    }
  },
  computed: {
    isLink() {
      if (typeof this.to === 'object') {
        return !!Object.keys(this.to).length
      }
      return !!this.to
    },
    itemStyles() {
      return {
        height: this.itemH,
        maxWidth: this.itemW,
        flexBasis: this.itemW,
        marginRight: this.offset,
      }
    },
  },
  methods: {
    onSelect(picture, iterator) {
      if (this.customAction) {
        this.$emit('click', picture)
        return
      }
      this.$emit('click', picture)
      this.selected = iterator
    },
    onHover(picture, iterator) {
      this.$emit('hover', picture)
      this.selected = iterator
    },
    onLeave() {
      this.$emit('leave')
    },
    handleButtonClick() {
      if (this.isLink) return
      this.$emit('click:more')
    },
  },
}
