//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'Popover',
  props: {
    name: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      showPopover: false,
    }
  },
  methods: {
    onOpen() {
      this.$emit('click:open', this.showPopover)
      this.showPopover = true
    },
    onClose() {
      this.showPopover = false
      this.$emit('click:close', this.showPopover)
    },
    toRoute() {
      switch (this.$props.name) {
      case 'user':
        this.$router.push('/profile')
        break
      case 'heart':
        this.$router.push('/profile/favorites')
        break
      case 'basket':
        this.$router.push('/cart')
        break
      }
    },
  },
}
