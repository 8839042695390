//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Hooper, Slide } from 'hooper'
import 'hooper/dist/hooper.css'
export default {
  name: 'Slider',
  components: {
    Hooper,
    Slide,
    DefaultArrows: () => import('./SliderArrows/DefaultArrows.vue'),
  },
  props: {
    slides: {
      type: Array,
      default: () => [],
      require: true,
    },
    rightArrowPos: {
      type: String,
      default: '',
    },
    leftArrowPos: {
      type: String,
      default: '',
    },
    paginations: {
      type: Boolean,
      default: true,
    },
    arrows: {
      type: Boolean,
      default: true,
    },
    autoplay: {
      type: Boolean,
      default: false,
    },
    settings: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      screenWidth: null,
      currentSlide: 0,
      dots: [],
      count: 0,
    }
  },

  computed: {
    showPrev() {
      return !!this.currentSlide
    },
    showNext() {
      return this.currentSlide + this.count < this.slides.length
    },
    activeDots() {
      const slide =
        this.currentSlide < 0
          ? this.dots.length - 1
          : this.currentSlide > this.dots.length - 1
            ? 0
            : this.currentSlide

      return slide / this.count
    },
    arrowsPositions() {
      return {
        ...(this.rightArrowPos && { rightArrowPos: this.rightArrowPos }),
        ...(this.leftArrowPos && { leftArrowPos: this.leftArrowPos }),
        showPrev: this.showPrev,
        showNext: this.showNext,
      }
    },
    options() {
      return {
        itemsToShow: 1,
        shortDrag: false,
        wheelControl: false,
        breakpoints: {
          320: {
            itemsToShow: 1,
          },
        },
        ...this.settings,
      }
    },
  },
  watch: {
    slides: {
      handler() {
        this.setScreen()
      },
      deep: true,
    },
  },

  mounted() {
    this.setScreen()
  },

  methods: {
    setScreen() {
      const timeout = setTimeout(() => {
        this.screenWidth = window.innerWidth
        for (const key in this.options.breakpoints) {
          if (Number(key) <= this.screenWidth) {
            this.count = this.options.breakpoints[key].itemsToShow
          }
        }
        if (this.paginations) {
          this.setDots()
        }
        clearTimeout(timeout)
      }, 0)
    },
    handleNext() {
      this.$refs.carousel.slideNext()
    },
    handlePrev() {
      this.$refs.carousel.slidePrev()
    },
    slideTo(i) {
      this.$refs.carousel.slideTo(i)
    },
    updateCarousel(payload) {
      this.currentSlide = payload.currentSlide
    },
    setDots() {
      this.count = 0
      this.dots = []
      for (const key in this.options.breakpoints) {
        if (Object.hasOwnProperty.call(this.options.breakpoints, key)) {
          if (Number(key) <= this.screenWidth) {
            this.count = this.options.breakpoints[key].itemsToShow
          }
        }
      }
      for (let i = 0; i < Math.ceil(this.slides.length / this.count); i++) {
        this.dots.push(i)
      }
    },
  },
}
