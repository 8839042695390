var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('label',{staticClass:"input",class:{
    'input--prepend': _vm.$slots.prepend,
    'input--append': _vm.$slots.append || _vm.clearable,
    'input--hidden-border': _vm.hideBorder && !_vm.focus,
    'input--focused': _vm.focus,
  }},[_vm._v("\n  "+_vm._s(_vm.label)+"\n  "),_c('div',{staticClass:"input-inner",style:({ height: _vm.height })},[(_vm.$slots.prepend)?_c('div',{staticClass:"input-prepend"},[_vm._t("prepend")],2):_vm._e(),_vm._v(" "),_c('input',_vm._g(_vm._b({ref:"input",staticClass:"input-input",attrs:{"disabled":_vm.disabled,"maxlength":_vm.maxLength,"name":_vm.name,"debounce":""},domProps:{"value":_vm.value},on:{"change":_vm.onChange,"input":_vm.onInput,"focus":_vm.onFocus,"blur":_vm.onBlur}},'input',_vm.attrs,false),_vm.listeners)),_vm._v(" "),(_vm.$slots.append || _vm.clearable)?_c('div',{staticClass:"input-pre-append",class:{
        'input-pre-append-active':
          (_vm.clearable && _vm.value.length) || _vm.showPassword,
      },on:{"click":_vm.onClickAppend}},[_c('span',{staticClass:"clear-btn"},[_c('icon',{staticClass:"clear-icon",attrs:{"w":"6px","h":"6px","icon":"cancel","stroke":_vm.$color.dark}})],1)]):_vm._e(),_vm._v(" "),(_vm.$slots.append)?_c('div',{staticClass:"input-append"},[_vm._t("append")],2):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }