//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { BREADCRUMBS } from '~/store/bc/types.bc'
import { IS_MOBILE, IS_TABLET } from '~/store/device/type.device'
export default {
  name: 'Breadcrumbs',
  computed: {
    isMobile() {
      return this.$store.getters[`device/${IS_MOBILE}`]
    },
    isTablet() {
      return this.$store.getters[`device/${IS_TABLET}`]
    },
    breadcrumbs() {
      const items = this.$store.getters[`bc/${BREADCRUMBS}`]
      if (this.isMobile || this.isTablet) {
        return items[items.length - 2]
      }
      return items
    },
  },
}
