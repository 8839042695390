//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'Burger',
  model: {
    event: 'click',
    prop: 'value',
  },
  props: {
    value: {
      type: Boolean,
      default: false,
      require: true,
    },
    w: {
      type: String,
      default: '24px',
    },
    h: {
      type: String,
      default: '24px',
    },
  },
  computed: {
    styles() {
      return {
        width: this.w,
        height: this.h,
      }
    },
  },
  methods: {
    onClick() {
      this.$emit('click', !this.value)
    },
  },
}
