//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'

export default {
  name: 'ProductPrice',
  props: {
    price: {
      type: Object,
      default: () => ({}),
    },
    isCard: {
      type: Boolean,
      default: false,
    },
    discountPriceInCart: {
      type: Boolean,
      default: false,
    },
    fontSize: {
      type: String,
      default: '22px',
    },
    fontSizePriceLeft: {
      type: String,
      default: '22px',
    },
    fontSizePriceRight: {
      type: String,
      default: '18px',
    },
    fontSizePriceBottom: {
      type: String,
      default: '18px',
    },
    visibleDiscount: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapState({
      settings: state => state.settings.settings,
    }),
    styles() {
      return {
        ...(this.fontSize && { fontSize: this.fontSize }),
      }
    },
    pricePercent() {
      return Math.round(((this.price.netto - this.price.discount_netto) / this.price.netto) * 100).toFixed(0) + '%'
    },

  },
}
