//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'Labels',
  props: {
    labels: {
      type: Array,
      default: () => [],
      required: true,
    },
    row: {
      type: Boolean,
      default: false,
    },
    w: {
      type: String,
      default: '',
    },
  },
  computed: {
    classes() {
      return [this.row && 'row']
    },
    styles() {
      return (color, bg) => {
        return {
          ...(color && { color: color.toLowerCase() }),
          ...(bg && { backgroundColor: bg }),
        }
      }
    },

    container() {
      return {
        ...(this.w && { maxWidth: this.w }),
      }
    },
  },
}
