//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'SearchList',
  components: {
    ProductMiniatureItem: () =>
      import('~/components/modules/Miniatures/ProductMiniatureItem.vue'),
  },
  props: {
    search: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
      default: () => [],
    },
    searchHistory: {
      type: Array,
      default: () => [],
    },
    isPending: {
      type: Boolean,
      default: false,
    },
    total: {
      type: Number,
      default: 0,
    },
  },

  methods: {
    onClick() {
      this.$emit('set:history', this.search)
      this.$emit('close')
    },
    onClickHistory(item) {
      this.$emit('click:history', item)
    },
  },
}
