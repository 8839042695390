//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Modal from './Modal.vue'
import getDeviceType from '~/mixins/getDeviceType'
export default {
  name: 'ProfileModal',
  components: { Modal },
  mixins: [getDeviceType],
  props: {
    title: {
      type: String,
      default: '',
    },
    width: {
      type: String,
      default: '566px',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onCancel() {
      this.$refs.modal.onClose()
      this.$emit('close')
    },
    onOpen() {
      this.$refs.modal.onOpen()
    },
    onAction() {
      this.$emit('click:action')
    },
  },
}
