//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'Dropdown',
  model: {
    prop: 'value',
    event: 'click',
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    border: {
      type: Boolean,
      default: false,
    },
    maxWidth: {
      type: [Number, String],
      default: 0,
    },
    minHeight: {
      type: [Number, String],
      default: 0,
    },
    borderRadius: {
      type: [Number, String],
      default: 0,
    },
  },
  computed: {
    styles() {
      const typeParams = (param) =>
        (obj[param] =
          typeof this[param] === 'number'
            ? `${this[param]}px`
            : /[^+\d]/.test(this[param])
              ? this[param]
              : `${this[param]}px`)
      const obj = {
        ...(this.border),
        // ...(this.border && { border: '1.5px solid grey' }),
      }
      this.maxWidth && typeParams('maxWidth')
      this.minHeight && typeParams('minHeight')
      this.borderRadius && typeParams('borderRadius')
      return obj
    },
  },

  watch: {
    value() {
      this.animateList()
    },
  },
  methods: {
    onToggle() {
      this.$emit('click', !this.value)
    },
    hide() {
      this.$emit('click', false)
    },
    animateList() {
      const self = this.$el
      const currentLink = self.querySelector('.current-item')
      const dropdownContent = self.querySelector('.dropdown-content')
      let timeout
      if (this.value) {
        currentLink.classList.add('active')
        dropdownContent.style.height = 'auto'
        const h = dropdownContent.getBoundingClientRect().height
        dropdownContent.style.height = '0'
        dropdownContent.style.opacity = '1'
        timeout = setTimeout(() => {
          dropdownContent.style.height = `${h}px`
          clearTimeout(timeout)
        }, 0)
      } else {
        currentLink.classList.remove('active')
        dropdownContent.style.height = '0px'
        timeout = setTimeout(() => {
          dropdownContent.removeAttribute('style')
          clearTimeout(timeout)
        }, 200)
      }
    },
    setHeightAuto() {
      const dropdownContent = this.$el.querySelector('.dropdown-content')
      dropdownContent.style.height = 'auto'
    },
  },
}
