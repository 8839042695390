//
//
//
//
//
//

export default {
  name: 'ProductStatus',
  props: {
    status: {
      type: Number,
      default: 0,
      required: true,
    },
  },
  computed: {
    inStock() {
      return this.status === 1
    },
    outOfStock() {
      return this.status === 2
    },
    preorder() {
      return this.status === 3
    },
    disabled() {
      return this.status === 10
    },
    classes() {
      return [
        this.inStock && 'status--in-stock',
        this.outOfStock && 'status--out-of-stock',
        this.preorder && 'status--preorder',
        this.disabled && 'status--disabled',
      ]
    },
  },
}
