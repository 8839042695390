//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'GoUp',
  props: {
    isFixed: {
      type: Boolean,
      default: false,
    },
    w: {
      type: String,
      default: '24px',
    },
    h: {
      type: String,
      default: '24px',
    },
  },
  data() {
    return {
      show: false,
    }
  },
  mounted() {
    if (this.isFixed) {
      window.addEventListener('scroll', this.onShow)
    }
  },

  beforeDestroy() {
    if (this.isFixed) {
      window.removeEventListener('scroll', this.onShow)
    }
  },
  methods: {
    onScroll() {
      window.scroll({
        behavior: 'smooth',
        top: 0,
      })
    },
    onShow() {
      const height = window.innerHeight
      if (window.scrollY >= height) {
        this.$refs.btn.classList.add('show')
        return
      }
      this.$refs.btn.classList.remove('show')
    },
  },
}
