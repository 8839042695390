//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Dropdown from './Dropdown.vue'
export default {
  name: 'DropdownSelect',
  components: { Dropdown },
  model: {
    prop: 'value',
    event: 'click',
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    value: {
      type: null,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
      default: () => [],
    },
    width: {
      type: [Number, String],
      default: 0,
    },
    rounded: {
      type: [Number, String],
      default: 0,
    },
    height: {
      type: String,
      default: '44px',
    },
    border: {
      type: Boolean,
      default: false,
    },
    multiselect: {
      type: Boolean,
      default: false,
    },
    inputType: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    rules: {
      type: Object,
      default: () => {},
    },
    errors: {
      type: [Array, Object],
      default: null,
    },
    iconColor: {
      type: String,
      default: '#000000',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      show: false,
      selected: false,
      focusInput: false,
      selected_item: null,
      textInput: '',
    }
  },
  computed: {
    styles() {
      return {
        height: this.height,
      }
    },
    modalStyles() {
      return {
        ...(this.width && { maxWidth: `${this.width}px` }),
      }
    },
    // isError() {
    //   if (this.errors.length) return this.errors[0]
    //   if (!this.rules || !this.rules.$dirty) return

    //   const keys = Object.keys(this.rules.$params).filter(
    //     (key) => !this.rules[key],
    //   )
    //   return keys.length
    //     ? this.$t(`errors.${keys[0]}`, { value: this.maxLength || 0 })
    //     : null
    // },
    isError() {
      if (this.errors) {
        return Array.isArray(this.errors) ? this.errors[0] : this.errors.message
      }
      if (!this.rules || !this.rules.$dirty) return

      const keys = Object.keys(this.rules.$params).filter(
        (key) => !this.rules[key],
      )
      return keys.length
        ? this.$t(`${keys[0]}`, {
          value: this.label,
          number: this.rules.$params[keys[0]].min || this.rules.$params[keys[0]].max,
        })
        : null
    },
  },
  watch: {
    show() {
      this.$emit('onChange', 'activeSelect', this.show)
    },
    value(val) {
      if(this.$props.inputType) {
        this.textInput = this.value
        if (val !== this.selected_item) {
          this.selected_item = val
        }
      }
    },
    items: {
      handler(value) {
        if(value.length === 1) {
          this.onSelect(value[0])
        }
      }
    },
    textInput: {
      handler(value) {
        if(value) {
          const item = { key: 1, type: value }
          this.onSelect(item)
        } else {
          this.selected_item = null
          this.selected = false
          this.$emit('click', '')
        }
      },
    },
  },
  created() {
    this.selected_item = this.value
    this.textInput = this.value
    if(this.selected_item) {
      this.selected = true
    }
  },
  methods: {
    onSelect(item) {
      if(this.selected_item && this.selected_item === item) {
        this.selected = false
        this.selected_item = null
        this.show = false
        this.$emit('click', '')
        return
      }
      this.selected_item = item
      this.selected = true
      this.show = false
      if(!this.inputType) {
        this.$emit('click', item)
      } else {
        this.$emit('click', item.type)
      }

      if(this.errors) {
        this.$emit('changeError', this.id)
      }

      if(this.rules) {
        this.rules.$touch()
      }

    },
    onFocus() {
      this.focusInput = true
      this.selected = true
    },
    onBlur() {
      this.focusInput = false
      if(!this.textInput) {
        this.selected = false
      }
    },
  },
}
