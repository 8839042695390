//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'Counter',
  model: {
    prop: 'value',
    event: 'click',
  },
  props: {
    value: {
      type: Number,
      default: 0,
      required: true,
    },
    disabledDec: {
      type: Boolean,
      default: false,
    },
    disabledInc: {
      type: Boolean,
      default: false,
    },
    height: {
      type: String,
      default: '48px',
    },
    width: {
      type: String,
      default: '51px',
    },
    widthCount: {
      type: String,
      default: '60px',
    },
    fontSize: {
      type: String,
      default: '22px',
    }
  },
  methods: {
    handleClick(type) {
      let value = this.value
      switch (type) {
      case 'inc':
        value++
        this.$emit('click:inc')
        break
      case 'dec':
        if (value === 0) return
        value--
        this.$emit('click:dec')
        break
      default:
        break
      }
      this.$emit('click', value)
    },
  },
}
