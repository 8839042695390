//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'DefaultArrows',
  props: {
    rightArrowPos: {
      type: String,
      default: '',
    },
    leftArrowPos: {
      type: String,
      default: '',
    },
    showPrev: {
      type: Boolean,
      default: true,
    },
    showNext: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    handleNext() {
      this.$emit('click:next')
    },
    handlePrev() {
      this.$emit('click:prev')
    },
  },
}
