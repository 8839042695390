//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'Input',
  components: {},
  props: {
    value: {
      type: String,
      default: '',
    },
    mask: {
      type: String,
      default: '',
    },
    height: {
      type: String,
      default: 'auto',
    },
    rules: {
      type: Object,
      default: () => {},
    },
    loading: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default: '',
    },
    maxLength: {
      type: null,
      default: undefined,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    hideBorder: {
      type: Boolean,
      default: false,
    },
    hideCounter: {
      type: Boolean,
      default: false,
    },
    showPassword: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      focus: false,
    }
  },
  computed: {
    attrs() {
      const {
        value,
        rules,
        errors,
        label,
        disabled,
        maxLength,
        mask,
        debounce,
        clearable,
        ...rest
      } = this.$attrs
      return rest
    },
    listeners() {
      const { input, focus, blur, change, ...rest } = this.$listeners
      return rest
    },
  },
  methods: {
    onInput(e) {
      this.$emit('input', e.target.value)
    },
    onChange(e) {
      this.$emit('change', e.target.value)
    },
    onFocus(e) {
      this.focus = true
      this.$emit('focus', e)
    },
    onBlur(e) {
      this.focus = !!this.value
      this.$emit('blur', e)
    },
    onClickAppend() {
      if (this.clearable) {
        this.$emit('input', '')
        this.$emit('clear')
      } else {
        this.$emit('click:append')
      }
    },
  },
}
