//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import expansionsFiles from '~/mixins/expansionsFiles'
export default {
  name: 'MediaFiles',
  mixins: [expansionsFiles],
  props: {
    item: {
      type: String,
      default: '',
      required: true,
    },

    importFrom: {
      type: String,
      default: '',
      required: false,
    },

    alt: {
      type: String,
      default: 'media',
      required: false,
    },

    cover: {
      type: Boolean,
      default: false,
    },

    h: {
      type: String,
      default: '',
    },
    w: {
      type: String,
      default: '',
    },
    videoSettings: {
      type: Object,
      default: () => ({
        autoplay: true,
        muted: true,
        loop: true,
      }),
    },
    disableOverlay: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    styles() {
      return {
        ...(this.h && { height: this.h }),
        ...(this.w && { width: this.w }),
      }
    },
  },

  methods: {
    onImageLoad() {
      if(this.importFrom === 'mainCategories') {
        this.$emit('onMainCategoryImageLoaded')
      }
    },
  },
}
