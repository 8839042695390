//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'Toggler',
  model: {
    event: 'click',
    prop: 'value',
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    w: {
      type: String,
      default: '42px',
    },
    h: {
      type: String,
      default: '24px',
    },
  },
  methods: {
    onClick(value) {
      this.$emit('click', value)
    },
  },
}
