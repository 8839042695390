//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'Table',
  props: {
    items: {
      type: Array,
      default: () => [],
      required: true,
    },
    fields: {
      type: Array,
      default: () => [
        {
          key: '',
          label: '',
          sortable: false,
        },
      ],
    },
    tableBlack: {
      type: Boolean,
      default: false,
    },
    withoutLateralIndents: {
      type: Boolean,
      default: false,
    },
    verticalPaddingHeaderColumn: {
      type: String,
      default: '9px',
    },
    verticalPaddingBodyColumn: {
      type: String,
      default: '16px',
    },
    horizontalPaddingBodyColumn: {
      type: String,
      default: '12px',
    }
  },
  computed: {
    thStyle() {
      return (item) => ({
        ...(item.sortable && {cursor: 'pointer'}),
        ...(item._width && {width: item._width}),
      })
    },
  },
  methods: {
    onHeadItemClick(key) {
      this.$emit('click:head-item', key)
    },
    onBodyItemClick(value) {
      this.$emit('click:body-item', value)
    },
  },
}
